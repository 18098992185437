import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Layout, Menu } from 'antd';
const Header = ({ location }) => (
  <Layout.Header
    style={{
      padding: '0 32px',background: '#DEE8FF'
    }}
  >
    <div
      style={{
        float: 'left',background: '#DEE8FF'
      }}
    >
      <h2
        style={{
          color: '#000',
          margin: 0,
          marginRight: '1em',
          display: 'inline',
          width: 100,
          lineHeight: '54px',
        }}
      >
        My Dashboard
      </h2>
    </div>
    <Menu
      theme="dark"
      mode="horizontal"
      selectedKeys={[location.pathname]}
      style={{
        lineHeight: '64px',background: '#DEE8FF'
      }}
    >
      <Menu.Item key="/explore">
        <Link  style={{
        color: '#000'
      }} to="/explore">Explore</Link>
      </Menu.Item>
      <Menu.Item key="/">
        <Link to="/" style={{
        color: '#000'
      }} >Dashboard</Link>
      </Menu.Item>
    </Menu>
  </Layout.Header>
);
export default withRouter(Header);
